/* FUENTES */

.partesBlanco {
    display: grid;
    grid-template-columns: repeat(3, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(2, [row-start] auto [row-end]);
}

.partesBlancoPreview {
    display: grid;
    grid-template-columns: repeat(2, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(2, [row-start] auto [row-end]);
    margin-top: 5em;
}

.partesBlanco .numeros {
    width: 18px;
    background-color: black;
    color: white;
    margin: 0 auto;
    padding: 0.5em;
    border-radius: 100%;
}

.pasoDos {
    grid-area: row-start 1 / col-start 1 /row-end 1 / col-end 1;
}

.pasoDos h1 {
    font-family: 'Roboto', sans-serif;
    color: #FFA849;
    margin-left: 1.5em;
}

h1 {
    margin: 0;
}

.apliBordOpciones {
    display: flex;
    flex-direction: column;
    gap: .5em;
    grid-area: row-start 2 / col-start 1 /row-end 1 / col-end 2;
    margin: 2em 0 0 2em;
    z-index: 1;
    width: fit-content;
    height: fit-content;
}

.apliBordOpciones span {
    background: hsla(31, 100%, 64%, 0.5);
    padding: 3px 10px 3px 10px;
    border-radius: 10px;
    color: white;
}

.apliBordOpciones span:hover {
    animation: aaa 1s;
    animation-fill-mode: forwards;
}

.manoTurn {
    grid-area: row-start 2 / col-start 3 /row-end 2 / col-end 3;
    margin: 5em 0 0 3.5em;
    z-index: 1;
}

@keyframes aaa {
    from {}

    to {
        background-color: #ffa74900;
        color: #ffa749d0;
        text-decoration: underline #FFA849;
    }
}

.frente {
    grid-area: row-start 2 / col-start 1 /row-end 2 / col-end 3;
    display: grid;
    grid-template-columns: repeat(10, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(5, [row-start] 14fr [row-end]);
    position: relative;
}

.frentePreview {
    grid-area: row-start 2 / col-start 1 /row-end 2 / col-end 1;
    display: grid;
    grid-template-columns: repeat(10, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(5, [row-start] 14fr [row-end]);
    position: relative;
    margin-top: 2em;
}

.espalda {
    grid-area: row-start 2 / col-start 1 /row-end 2 / col-end 3;
    display: grid;
    grid-template-columns: repeat(10, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(5, [row-start] 14fr [row-end]);
    position: relative;
}

.espaldaPreview {
    grid-area: row-start 2 / col-start 2 /row-end 2 / col-end 2;
    display: grid;
    grid-template-columns: repeat(10, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(5, [row-start] 14fr [row-end]);
    position: relative;
    margin-top: 2em;
}

.oculto {
    display: none;
}

/* ////////////APLIQUES BUZO Y BUZO SIMPLE/////////////// */

.probando1 {
    position: absolute;
    top: 60%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probando2 {
    position: absolute;
    top: 50%;
    left: 30%;
    width: 15%;
    text-align: center;
    z-index: 1;
}

.probando3 {
    position: absolute;
    top: 50%;
    left: 55%;
    width: 15%;
    text-align: center;
    z-index: 1;
}

.probando4 {
    position: absolute;
    top: 68%;
    left: 1%;
    width: 41%;
    text-align: center;
    transform: rotate(-76deg);
    z-index: 1;
}

.probando5 {
    position: absolute;
    top: 68%;
    left: 53%;
    min-width: 51%;
    text-align: center;
    transform: rotate(76deg);
    z-index: 1;
}

.probando6 {
    position: absolute;
    top: 64%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probando7 {
    position: absolute;
    top: 56%;
    left: 5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probando8 {
    position: absolute;
    top: 83%;
    left: -2.5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probando9 {
    position: absolute;
    top: 56%;
    left: 55%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probando10 {
    position: absolute;
    top: 83%;
    left: 62.5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probando11 {
    position: absolute;
    top: 70%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probando11BSimple {
    position: absolute;
    top: 67%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probando12 {
    position: absolute;
    top: 83.5%;
    left: 37%;
    width: 12%;
    text-align: center;
    z-index: 1;
}

.probando13 {
    position: absolute;
    top: 83.5%;
    left: 51%;
    width: 12%;
    text-align: center;
    z-index: 1;
}

.probando14 {
    position: absolute;
    top: 50%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probando15 {
    position: absolute;
    top: 86%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

/* APLIQUES PREVIEW */
.probandoPreview1 {
    position: absolute;
    top: 55%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.probandoPreview2 {
    position: absolute;
    top: 45%;
    left: 30%;
    width: 15%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.probandoPreview3 {
    position: absolute;
    top: 45%;
    left: 55%;
    width: 15%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.probandoPreview4 {
    position: absolute;
    top: 63%;
    left: 1%;
    width: 41%;
    text-align: center;
    transform: rotate(-76deg);
    z-index: 1;
    font-size: 12px;

}

.probandoPreview5 {
    position: absolute;
    top: 63%;
    left: 53%;
    min-width: 51%;
    text-align: center;
    transform: rotate(76deg);
    z-index: 1;
    font-size: 12px;

}

.probandoPreview6 {
    position: absolute;
    top: 59%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.probandoPreview7 {
    font-size: 12px;
    position: absolute;
    top: 52%;
    left: 5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoPreview8 {
    font-size: 12px;
    position: absolute;
    top: 79%;
    left: -2.5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoPreview9 {
    font-size: 12px;
    position: absolute;
    top: 52%;
    left: 55%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoPreview10 {
    font-size: 12px;
    position: absolute;
    top: 79%;
    left: 62.5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoPreview11 {
    font-size: 12px;
    position: absolute;
    top: 64%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoPreview12 {
    font-size: 12px;
    position: absolute;
    top: 79.5%;
    left: 23%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoPreview13 {
    font-size: 12px;
    position: absolute;
    top: 79.5%;
    left: 37%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoPreview14 {
    font-size: 12px;
    position: absolute;
    top: 43%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoPreview15 {
    font-size: 12px;
    position: absolute;
    top: 82%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

/* /////////////////////////// */

/* ////////////APLIQUES BUZO DOBLE/////////////// */
.apliqueBuzoDoble1 {
    position: absolute;
    top: 65.5%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoble2 {
    position: absolute;
    top: 53%;
    left: 30%;
    width: 15%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoble3 {
    position: absolute;
    top: 53%;
    left: 55%;
    width: 15%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoble4 {
    position: absolute;
    top: 63%;
    left: 2%;
    width: 41%;
    text-align: center;
    transform: rotate(-76deg);
    z-index: 1;
}

.apliqueBuzoDoble5 {
    position: absolute;
    top: 63%;
    left: 52%;
    min-width: 51%;
    text-align: center;
    transform: rotate(76deg);
    z-index: 1;
}

.apliqueBuzoDoble6 {
    position: absolute;
    top: 65.5%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoble7 {
    position: absolute;
    top: 84%;
    left: -3%;
    width: 41%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoble8 {
    position: absolute;
    top: 84%;
    left: 62%;
    width: 41%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoble9 {
    position: absolute;
    top: 84%;
    left: 37%;
    width: 12%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoble10 {
    position: absolute;
    top: 84%;
    left: 51%;
    width: 12%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoble11 {
    position: absolute;
    top: 47%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoble12 {
    position: absolute;
    top: 86%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoDoblePreview1 {
    position: absolute;
    top: 60.5%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.apliqueBuzoDoblePreview2 {
    position: absolute;
    top: 45%;
    left: 30%;
    width: 15%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.apliqueBuzoDoblePreview3 {
    position: absolute;
    top: 45%;
    left: 55%;
    width: 15%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.apliqueBuzoDoblePreview4 {
    position: absolute;
    top: 58%;
    left: 2%;
    width: 41%;
    text-align: center;
    transform: rotate(-76deg);
    z-index: 1;
    font-size: 12px;

}

.apliqueBuzoDoblePreview5 {
    position: absolute;
    top: 58%;
    left: 52%;
    min-width: 51%;
    text-align: center;
    transform: rotate(76deg);
    z-index: 1;
    font-size: 12px;

}

.apliqueBuzoDoblePreview6 {
    position: absolute;
    top: 60.5%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

/* /////////////////////////// */

/* ////////////BUZO V DOBLE/////////////// */
.apliqueBuzoVDoble6 {
    position: absolute;
    top: 73%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.apliqueBuzoVDoblePreview6 {
    position: absolute;
    top: 66%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
    font-size: 12px;
}

/* /////////////////////////// */

/* /////////////APLIQUES CAMPERA////////////// */
.apliqueCampera4 {
    position: absolute;
    top: 64%;
    left: 1%;
    width: 41%;
    text-align: center;
    transform: rotate(-76deg);
    z-index: 1;
}

.apliqueCampera5 {
    position: absolute;
    top: 64%;
    left: 53%;
    min-width: 51%;
    text-align: center;
    transform: rotate(76deg);
    z-index: 1;
}

.probandoCampera7 {
    position: absolute;
    top: 52%;
    left: 5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoCampera8 {
    position: absolute;
    top: 77%;
    left: -2.5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoCampera9 {
    position: absolute;
    top: 52%;
    left: 55%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoCampera10 {
    position: absolute;
    top: 77%;
    left: 62.5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoCampera11 {
    position: absolute;
    top: 64%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoCampera12 {
    position: absolute;
    top: 76.5%;
    left: 37%;
    width: 12%;
    text-align: center;
    z-index: 1;
}

.probandoCampera13 {
    position: absolute;
    top: 76.5%;
    left: 51%;
    width: 12%;
    text-align: center;
    z-index: 1;
}

.probandoCampera14 {
    position: absolute;
    top: 44%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.probandoCampera15 {
    position: absolute;
    top: 78%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.previewCampera1 {
    position: absolute;
    top: 55%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.previewCampera2 {
    position: absolute;
    top: 43%;
    left: 30%;
    width: 15%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.previewCampera3 {
    position: absolute;
    top: 43%;
    left: 55%;
    width: 15%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.previewCampera4 {
    position: absolute;
    top: 60%;
    left: 1%;
    width: 41%;
    text-align: center;
    transform: rotate(-76deg);
    z-index: 1;
    font-size: 12px;

}

.previewCampera5 {
    position: absolute;
    top: 60%;
    left: 53%;
    min-width: 51%;
    text-align: center;
    transform: rotate(76deg);
    z-index: 1;
    font-size: 12px;

}

.previewCampera6 {
    position: absolute;
    top: 59%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
    font-size: 12px;

}

.previewCampera7 {
    font-size: 12px;
    position: absolute;
    top: 47%;
    left: 5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.previewCampera8 {
    font-size: 12px;
    position: absolute;
    top: 74%;
    left: -2.5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.previewCampera9 {
    font-size: 12px;
    position: absolute;
    top: 47%;
    left: 55%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.previewCampera10 {
    font-size: 12px;
    position: absolute;
    top: 74%;
    left: 62.5%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.previewCampera11 {
    font-size: 12px;
    position: absolute;
    top: 64%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.previewCampera12 {
    font-size: 12px;
    position: absolute;
    top: 73.5%;
    left: 23%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.previewCampera13 {
    font-size: 12px;
    position: absolute;
    top: 73.5%;
    left: 37%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.previewCampera14 {
    font-size: 12px;
    position: absolute;
    top: 39%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.previewCampera15 {
    font-size: 12px;
    position: absolute;
    top: 76%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

/* /////////////////////////// */

/* ////////////APLIQUE CAMPERA DOBLE/////////////// */
.apliqueCamperaDoble1 {
    position: absolute;
    top: 65.5%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.apliqueCamperaDoble4 {
    position: absolute;
    top: 64%;
    left: 3%;
    width: 41%;
    text-align: center;
    transform: rotate(-76deg);
    z-index: 1;
}

.apliqueCamperaDoble5 {
    position: absolute;
    top: 64%;
    left: 51%;
    min-width: 51%;
    text-align: center;
    transform: rotate(76deg);
    z-index: 1
}

.apliqueCamperaDoble6 {
    position: absolute;
    top: 65.5%;
    left: 30%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.bordadoDoble7 {
    position: absolute;
    top: 63%;
    left: 3%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

.bordadoDoble9 {
    position: absolute;
    top: 63%;
    left: 57%;
    width: 40%;
    text-align: center;
    z-index: 1;
}

/* /////////////////////////// */


.fix {
    margin: 0;
    font-size: 30px;
    overflow: hidden;
}

.fixPreview {
    margin: 0;
    font-size: 21px;
    overflow: hidden;
}

.display {
    visibility: hidden;
}

.displayFixed {
    display: none;
}

.dorso {
    max-width: 100%;
    grid-area: row-start 1 / col-start 8 /row-end 1 / col-end 9;
}

.asd {
    max-width: 100%;
    grid-area: row-start 2 / col-start 2 / row-end 5 / col-end 9;
}

.dsa {
    width: 100%;
    height: 89%;
}

.contorno {
    z-index: 1;
}

.arreglo {
    width: 100%;
    height: 89%;
}

.white {
    filter: invert(100%) sepia(0%) saturate(7471%) hue-rotate(23deg) brightness(100%) contrast(97%);
}



.anton {
    font-family: 'Anton', sans-serif
}

.xanh {
    font-family: 'Xanh Mono', monospace;
}

.bungee {
    font-family: 'Bungee', cursive;
}

.cookie {
    font-family: 'Cookie', cursive;
}

@keyframes open {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (min-width: 520px) and (max-width:1023px) {
    .partesBlanco {
        margin-top: 4em;
    }

    .pasoDos h1 {
        font-size: 200%;
    }

    .apliBordOpciones span {
        font-size: 200%;
    }
}