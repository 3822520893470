.modalPreview {
    min-width: 100%;
    min-height: 100%;
    background-color: white;
    z-index: 1;
    top: 0;
    position: fixed;
}

.modalAux {
    background-image: url(../../imgs//navBar/fondoModalPreview.svg);
    background-size: cover;
    min-width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.modalAux button {
    width: fit-content;
    margin: 0 auto;
    border: solid white 1px;
    border-radius: 20px;
    background-color: #00bb2d;
    padding: 1em 1.5em 1em 1.5em;

}

.modalAux button a {
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
}

.modalAux button a img {
    filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);
}

.instrucciones {
    display: flex;
    margin-bottom: -12em;
}

.modalAux li {
    margin-bottom: .5em;
}

.modalAux h1 {
    margin: 2px 0 0 .5em;
}

.closePreview {
    width: 30%;
    z-index: 1;
}

.modalAux span img {
    margin: .5em;
    width: 70%;
}

.displayPreview {
    display: none;
}

.keysValues {
    display: flex
}

.ulSeccionesPreview {
    display: flex;
    flex-direction: column;
    gap: .5em;
    padding-left: 40px;
}

.ulSeccionesPreview li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    height: 25px;
}

.circuloPreview {
    padding: 0px 10px;
    width: -1px;
    width: 0px;
    height: 20px;
    border-radius: 50%;
    outline: solid 1.5px;
    outline-offset: 2px;
    margin-left: 1em;
}

.previewAPliBord {
    display: flex;
    flex-direction: column;
    gap: .5em;
    padding-left: 40px;
}

.previewAPliBord li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    height: 25px;
}

@media screen and (min-width: 520px) and (max-width:1023px) {
    .ulSeccionesPreview li {
        font-size: 200%;
    }

    .previewAPliBord li {
        font-size: 200%;
    }

    .instrucciones div {
        font-size: 200%
    }
}