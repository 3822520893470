nav {
    background-image: url("../../imgs/navBar/fondoNav.svg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 6.096rem;
    max-height: 6.096rem;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(8, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(5, [row-start] auto [row-end]);
}

.logo {
    grid-area: row-start 2 / col-start 2 / row-end 4 / col-end 4;
}

.harvey {
    grid-area: row-start 3 / col-start 4 / row-end 3 / col-end 5;
}

.wsp {
    grid-area: row-start 3 / col-start 7 / row-end 3 / col-end 7;
    display: flex;
    justify-content: center;
}

.email {
    grid-area: row-start 3 / col-start 8 / row-end 3 / col-end 8;
    display: flex;
    justify-content: center;
}

.cuerpo {
    background-image: url("../../imgs//navBar/fondoCuerpo.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 8.5em;
    padding-bottom: 20em;
}

.cuerpo .primerPaso {
    margin-top: 6em;
}

.cuerpo .primerPaso h1 {
    color: #FFA849;
    margin-left: 1.5em;
}

.subtitleSpan {
    margin-left: 2.2em;
}

.opciones {
    display: flex;
    flex-direction: column;
    position: relative;
}

.opciones span {
    z-index: 1;
}


.opciones span img {
    width: 10em;
    padding: 1em;
    border-radius: 10px;
}

.manoSlice {
    position: absolute;
    width: 10%;
    top: 50%;
    left: 45%;
}

.camperaBuzo {
    display: flex;
    justify-content: center;
}


.buzo {
    grid-area: row-start 1 / col-start 2 / row-end 1 / col-end 2;
    text-align: center;
}

.buzo:hover {
    transform: scale(0.95);
    border: solid 1px #FFA849;
    color: #FFA849;
}

.campera {
    grid-area: row-start 1 / col-start 3 / row-end 1 / col-end 3;
    text-align: center;
}

.campera:hover {
    transform: scale(0.95);
    border: solid 1px #FFA849;
    color: #FFA849;
}

.scrollXBuzo {
    grid-area: row-start 2 / col-start 2 / row-end 2 / col-end 3;
    text-align: center;
    overflow: scroll;
    padding-left: 10px;
}

.scrollXBuzo span:hover {
    transform: scale(0.95);
    border: solid 1px #FFA849;
    color: #FFA849;
}

.scrollXBuzo img {
    margin: 0 .1em 0 .1em
}

.scrollXBuzo div {
    display: flex;
}

.scrollXCampera {
    grid-area: row-start 3 / col-start 2 / row-end 3 / col-end 3;
    text-align: center;
    overflow: scroll;
    padding-left: 10px;
}

::-webkit-scrollbar {
    display: none;
}

.scrollXCampera span:hover {
    transform: scale(0.95);
    border: solid 1px #FFA849;
    color: #FFA849;
}

.scrollXCampera img {
    margin: 0 .1em 0 .1em
}

.scrollXCampera div {
    display: flex;
}

.disable {
    display: none;
}

footer {
    margin-top: -16em;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(3, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(3, [row-start] auto [row-end]);
    grid-row-gap: 1em;
    background-image: url("../../imgs/footer/fondoFooter.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    padding-bottom: 3em;
}

.logoFooter {
    grid-area: row-start 1 / col-start 2 / row-end 1 / col-end 2;
}

.harveyFooter {
    grid-area: row-start 2 / col-start 1 / row-end 2 / col-end 3;
}

.disenaTuPrenda {
    grid-area: row-start 3 / col-start 1 / row-end 3 / col-end 1;
}

.facebook {
    grid-area: row-start 3 / col-start 2 / row-end 3 / col-end 2;
}

.instagram {
    grid-area: row-start 3 / col-start 3 / row-end 3 / col-end 3;
}

.modalOnlyMobile {
    min-width: 100%;
    min-height: 100%;
    top: 0;
    position: fixed;
    background-color: gainsboro;
    z-index: 1;
    display: none;
}

.modalOnlyMobile div {
    background-color: rgba(255, 255, 255, 0.5);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalOnlyMobile img {
    width: 80%;
}



@media screen and (min-width: 520px) and (max-width:1023px) {
    .cuerpo {
        min-height: 100vw;
    }

    .cuerpo .primerPaso {
        margin-top: 20em;
    }

    .camperaBuzo {
        margin-top: 5%;
        gap: 10%;
    }

    .cuerpo .primerPaso h1 {
        font-size: 200%;
    }

    .subtitleSpan {
        font-size: 150%;
    }

    .harvey {
        display: flex;
        justify-content: center;
    }

    .harvey img {
        width: 80%;
    }

    .logo {
        width: 25%;
    }

    .wsp img {
        width: 25%;
    }

    .email img {
        width: 25%;
    }
}

@media screen and (min-width:1023px) {
    .modalOnlyMobile {
        display: block;
    }
}