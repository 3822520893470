.modal {
    background-color: rgba(255, 255, 255);
    min-height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    animation: open .5s;
    animation-fill-mode: forwards;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2em;
}

.modal img {
    margin: 1em 5% 0 0;
    width: 8%;
}

.modal p {
    width: 90%;
    margin: 0 5% 0 5%;
    text-align: justify;
}

.modal input {
    width: 90%;
    margin: 0 5% 0 5%;
    border: none;
    border-bottom: dotted 2px #FFA849;
    padding-bottom: .5em;
    font-size: larger;
}

.modal input:focus {
    outline: none;
}

.modal button {
    margin-right: 5%;
    background-color: #FFA849;
    border: none;
    border-radius: 30px;
    padding: 1em 2em 1em 2em;
}

.modal select {
    width: 90%;
    font-size: larger;
    padding: .5em;
    margin: 0 auto;
    border: 1px solid #FFA849;
    border-radius: 5px;
}

.open {
    display: none;
}

.selectoresModal {
    display: block;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;
}



.pruebaModal {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    padding: 1em;
    height: fit-content;
    width: 1092px;
}

.pruebaModal div {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline: solid 1.5px;
    outline-offset: 2px;
}