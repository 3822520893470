.titleSelector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 90%;
    margin: 0 auto;
}

.listaSecciones {
    width: 100%;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    overflow: hidden;
    height: 0;
}

.none {
    animation: partes .5s;
    animation-fill-mode: forwards;
}

.flipArrow {
    animation: flip .5s;
    animation-fill-mode: forwards;
}

@keyframes flip {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

@keyframes partes {
    from {
        width: 0;
    }

    to {
        width: 100%;
        height: 100%;
    }
}

.listaSecciones li {
    margin: 0 auto;
    width: 70%;
    background-color: #FFA749;
    height: 1.5em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.listaSecciones li:hover {
    border: solid 1px #FFA849;
    background-color: white;
    color: #FFA849;
}

.selectores {
    display: block;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;
}

.prueba {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    padding: 1em;
    height: fit-content;
    width: 1092px;
}

.prueba div {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline: solid 1.5px;
    outline-offset: 2px;
}

.inactive {
    display: none;
}


.paso3 {
    width: 100%;
    display: flex;
    justify-content: center;
}

.paso3 button {
    margin-top: 2em;
}

.utimoPaso {
    background: hsla(31, 100%, 64%, 0.5);
    padding: 10px 18px 10px 18px;
    border-radius: 10px;
    color: white;
    border: none;
    margin-left: 8em;
    border: solid 3px darkorange;
    box-shadow: 0 1em 2em -1em lightslategrey;
}

.warningUltimoPaso {
    color: red;
    width: 70%;
    margin: 1em auto;
    text-align: justify;
}

.loQuieroSin {
    background: hsla(31, 100%, 64%, 0.5);
    padding: 10px 18px 10px 18px;
    border-radius: 10px;
    border: none;
    border: solid 3px darkorange;
    box-shadow: 0 1em 2em -1em lightslategrey;
    width: 65%;
    margin: 2em auto;
}

.color4b4c80 {
    filter: invert(28%) sepia(60%) saturate(438%) hue-rotate(199deg) brightness(93%) contrast(87%);
}

.color6265a3 {
    filter: invert(45%) sepia(5%) saturate(5171%) hue-rotate(199deg) brightness(84%) contrast(72%);
}

.colorb1b4da {
    filter: invert(86%) sepia(18%) saturate(1286%) hue-rotate(189deg) brightness(88%) contrast(94%);
}

.colorb25295 {
    filter: invert(40%) sepia(90%) saturate(351%) hue-rotate(265deg) brightness(88%) contrast(94%);
}

.colorfc5cad {
    filter: invert(58%) sepia(59%) saturate(3414%) hue-rotate(298deg) brightness(101%) contrast(98%);
}

.colord26eaa {
    filter: invert(62%) sepia(22%) saturate(1244%) hue-rotate(279deg) brightness(87%) contrast(87%);
}

.colordc80b4 {
    filter: invert(82%) sepia(5%) saturate(6522%) hue-rotate(285deg) brightness(88%) contrast(95%);
}

.colore7abcd {
    filter: invert(97%) sepia(17%) saturate(4202%) hue-rotate(281deg) brightness(107%) contrast(81%);
}

.colorf9b9b4 {
    filter: invert(89%) sepia(65%) saturate(2464%) hue-rotate(292deg) brightness(108%) contrast(95%);
}

.colorf9d9e4 {
    filter: invert(100%) sepia(48%) saturate(1468%) hue-rotate(284deg) brightness(101%) contrast(92%);
}

.colorf6ebda {
    filter: invert(92%) sepia(87%) saturate(304%) hue-rotate(299deg) brightness(107%) contrast(93%);
}

.colorfbcc91 {
    filter: invert(96%) sepia(8%) saturate(6085%) hue-rotate(305deg) brightness(120%) contrast(97%);
}

.colorec4750 {
    filter: invert(40%) sepia(50%) saturate(1989%) hue-rotate(327deg) brightness(92%) contrast(102%);
}

.colord48432 {
    filter: invert(56%) sepia(11%) saturate(2404%) hue-rotate(349deg) brightness(98%) contrast(99%);
}

.coloree5f32 {
    filter: invert(41%) sepia(43%) saturate(949%) hue-rotate(329deg) brightness(106%) contrast(100%);
}

.colore2263b {
    filter: invert(24%) sepia(72%) saturate(2792%) hue-rotate(337deg) brightness(90%) contrast(97%);
}

.colorcc526d {
    filter: invert(41%) sepia(26%) saturate(1085%) hue-rotate(297deg) brightness(101%) contrast(104%);
}

.color752142 {
    filter: invert(13%) sepia(72%) saturate(2183%) hue-rotate(313deg) brightness(93%) contrast(88%);
}

.color6d2232 {
    filter: invert(15%) sepia(76%) saturate(1166%) hue-rotate(313deg) brightness(98%) contrast(94%);
}

.color441f3a {
    filter: invert(11%) sepia(73%) saturate(801%) hue-rotate(271deg) brightness(92%) contrast(89%);
}

.color0a2333 {
    filter: invert(10%) sepia(8%) saturate(6511%) hue-rotate(164deg) brightness(100%) contrast(94%);
}

.color0c283f {
    filter: invert(15%) sepia(18%) saturate(1683%) hue-rotate(164deg) brightness(98%) contrast(102%);
}

.color50649b {
    filter: invert(39%) sepia(9%) saturate(3206%) hue-rotate(186deg) brightness(88%) contrast(73%);
}

.color384e78 {
    filter: invert(27%) sepia(9%) saturate(3122%) hue-rotate(181deg) brightness(94%) contrast(83%);
}

.color313bb5 {
    filter: invert(12%) sepia(57%) saturate(5964%) hue-rotate(236deg) brightness(103%) contrast(79%);
}

.color859da8 {
    filter: invert(70%) sepia(7%) saturate(917%) hue-rotate(154deg) brightness(86%) contrast(87%);
}

.color767cb4 {
    filter: invert(54%) sepia(35%) saturate(463%) hue-rotate(196deg) brightness(86%) contrast(91%);

}

.color52d5db {
    filter: invert(77%) sepia(61%) saturate(421%) hue-rotate(130deg) brightness(89%) contrast(93%);

}

.colora4c5da {
    filter: invert(85%) sepia(31%) saturate(501%) hue-rotate(174deg) brightness(89%) contrast(92%);

}

.colorcce7f6 {
    filter: invert(99%) sepia(22%) saturate(7359%) hue-rotate(175deg) brightness(105%) contrast(93%);

}

.colord0eae3 {
    filter: invert(98%) sepia(2%) saturate(3657%) hue-rotate(163deg) brightness(91%) contrast(100%);

}

.color7fe5e5 {
    filter: invert(82%) sepia(97%) saturate(248%) hue-rotate(123deg) brightness(95%) contrast(89%);

}

.coloraeee44 {
    filter: invert(100%) sepia(23%) saturate(5939%) hue-rotate(22deg) brightness(99%) contrast(88%);

}

.colorb0c480 {
    filter: invert(95%) sepia(6%) saturate(2316%) hue-rotate(22deg) brightness(84%) contrast(82%);

}

.color62cc95 {
    filter: invert(70%) sepia(22%) saturate(776%) hue-rotate(96deg) brightness(97%) contrast(93%);

}

.color48997a {
    filter: invert(60%) sepia(43%) saturate(457%) hue-rotate(105deg) brightness(81%) contrast(84%);

}

.color4a8a99 {
    filter: invert(51%) sepia(30%) saturate(631%) hue-rotate(144deg) brightness(91%) contrast(84%);

}

.color4a7c4b {
    filter: invert(40%) sepia(41%) saturate(433%) hue-rotate(72deg) brightness(98%) contrast(90%);

}

.color324441 {
    filter: invert(21%) sepia(17%) saturate(621%) hue-rotate(120deg) brightness(93%) contrast(85%);

}

.colorc2d19d {
    filter: invert(89%) sepia(11%) saturate(756%) hue-rotate(35deg) brightness(91%) contrast(90%);

}

.colorab9370 {
    filter: invert(59%) sepia(1%) saturate(7220%) hue-rotate(355deg) brightness(99%) contrast(97%);

}

.colorefc867 {
    filter: invert(77%) sepia(68%) saturate(356%) hue-rotate(350deg) brightness(99%) contrast(89%);

}

.colorffda40 {
    filter: invert(78%) sepia(98%) saturate(385%) hue-rotate(338deg) brightness(102%) contrast(101%);

}

.colorf9eaac {
    filter: invert(89%) sepia(32%) saturate(430%) hue-rotate(340deg) brightness(104%) contrast(95%);

}

.colorffffff {
    filter: invert(100%) sepia(0%) saturate(7484%) hue-rotate(80deg) brightness(108%) contrast(100%);

}

.colorfffff5 {
    filter: invert(94%) sepia(29%) saturate(249%) hue-rotate(36deg) brightness(111%) contrast(106%);

}

.colorcecece {
    filter: invert(91%) sepia(11%) saturate(0%) hue-rotate(220deg) brightness(98%) contrast(75%);

}

.colorb9b9b9 {
    filter: invert(83%) sepia(0%) saturate(7472%) hue-rotate(219deg) brightness(98%) contrast(71%);

}

.colorcec8ba {
    filter: invert(88%) sepia(16%) saturate(175%) hue-rotate(4deg) brightness(93%) contrast(84%);

}

.colorc4c4c4 {
    filter: invert(91%) sepia(18%) saturate(0%) hue-rotate(140deg) brightness(86%) contrast(84%);

}

.color514d46 {
    filter: invert(27%) sepia(2%) saturate(2655%) hue-rotate(360deg) brightness(97%) contrast(83%);

}

.colorb7b7b7 {
    filter: invert(79%) sepia(1%) saturate(2816%) hue-rotate(69deg) brightness(93%) contrast(93%);

}

.color606060 {
    filter: invert(39%) sepia(5%) saturate(5%) hue-rotate(89deg) brightness(94%) contrast(93%);

}

.color070707 {
    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(91deg) brightness(93%) contrast(99%);

}

@media screen and (min-width: 520px) and (max-width:1023px) {
    .listaSecciones li {
        font-size: 200%;
    }

    .titleSelector {
        font-size: 200%;
    }

    .prueba{
        width: 2092px;
    }

    .prueba div {
        width: 45px;
        height: 45px;
    }
}
